import moment from "moment";
import { COOKIE_NAME } from "../constants/texts";
import { config } from 'constants/config'
import { get } from "lodash";


export const getCookie = () => {
  return document.cookie.match(`(^|;)?${COOKIE_NAME}=([^;]*)(;|$)`);
};

export const deleteCookie = () => {
  document.cookie = "TIGO_ACCOUNTS= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
};

export const isEmpty = (obj) => {
  if (Object.keys(obj).length) {
    return false;
  }
  return true;
};

export const getCountry = () => {
  if (process.env.REACT_APP_COUNTRY) {
    return process.env.REACT_APP_COUNTRY;
  }
  return "SV";
};

export const autoCapitalize = (str) => {
  if (str) {
    return str?.replace(
      /\w\S*/g,
      (txt) =>
        txt.toLowerCase().charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    );
  }
  return "";
};

export const convertSalesStatusToESLocale = (status) => {
  switch (status) {
    case "COMPLETE":
    case "Completed":
      return "Completado";
    case "REQUESTED":
      return "Pendiente";
    case "REJECTED":
      return "Rechazado";
    case "TOMODIFY":
      return "Accion necesaria";
    default:
      return status;
  }
};

export const filterPackages = (packagesList, packageType) => {
  const singleCategories = [
    "Single tv",
    "Single internet",
    "Single fixed",
    "Single DTH",
  ];
  if (packageType === "/singles") {
    const tempList = packagesList.filter((i) => {
      const offer = i.offerType.type || i.offerType;
      if (typeof offer === "string") {
        return offer !== "activation supplementary";
      }
      return offer.includes("activation primary");
    });
    if (tempList.length) {
      return tempList.filter((t) => singleCategories.includes(t.type));
    }
    return [];
  }

  return packagesList.filter((i) => {
    const offer = i.offerType.type || i.offerType;
    if (typeof offer === "string") {
      return offer !== "activation supplementary";
    }
    return offer.includes("activation primary");
  });
};

export const validateFormFields = (field, value, validateOnChange = true, docType = "DUI",) => {
  if (!validateOnChange && !value) return false;
  const phonePattern = /^[0-9 ]*$/;
  const passportPattern = /^[a-zA-ZñáéíóúÁÉÍÑÓÚ1234567890#@&:'_.,-\s]*$/

  switch (field) {
    case "email":
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value) || /^[a-zA-Z0-9._@-]*$/.test(value);
    case "telephone":
    case "cliTele":
    case 'cliTelephone':
      if (getCountry() === 'CO') {
        const formattedValue = removeWhiteSpace(replaceSpecialChars(value))
        return phonePattern.test(formattedValue) && formattedValue[0] === '3' && formattedValue.length === 10;
      }
      return phonePattern.test(value);
    case "cliDUINumber":
      return docType === "DUI"
        ? phonePattern.test(value)
        : passportPattern.test(value);
    case "name":
    case "surname":
    case "cliFirstName":
    case "cliLastName":
    case "refNameA":
    case "refNameB":
    case "actDepartment":
    case "actCity":
    case "actCounty":
    case "actStreet":
    case "cliProfession":
      const namePattern = /^[A-Za-zñáéíóúÁÉÍÑÓÚ ]*$/;
      return namePattern.test(value);
    case 'expirationYear':
    case 'expirationDate':
    case 'cliTelephone':
    case 'cliTelephoneSecondary':
    case "verify_otp":
    case 'residencyNumber':
    case 'locality':
      return phonePattern.test(value);
    case 'documentNumber':
      if (getCountry() === 'HN') {
        return passportPattern.test(value)
      }
      return true
    case 'addressref':
      const stringnumPatterm = /^[a-zA-ZñáéíóúÁÉÍÑÓÚ1234567890 ]*$/;
      if (getCountry() === 'HN') {
        value = value.replace(/(\r\n|\n|\r)/gm, "");
      }
      return stringnumPatterm.test(value);
    case 'NIT':
      return nitIsValid(value)
    default:
      return true;
  }
};

export const getDisabledStatus = (buttonId, formField, currentSource) => {
  let disabled = false;
  switch (buttonId) {
    case "submit":
      formField.forEach((field) => {
        if (
          (typeof currentSource[field.id] !== "number" &&
            !currentSource[field.id]) ||
          (typeof currentSource[field.id] === "number" &&
            currentSource[field.id]) === undefined
        ) {
          disabled = true;
          return;
        }
      });
      return disabled;
    case "cancel":
      disabled = true;
      formField.forEach((field) => {
        if (currentSource[field.id]) {
          disabled = false;
          return;
        }
      });
      return disabled;
    default:
      return disabled;
  }
};

export const convertDataToFile = async (
  field,
  filePath,
  url,
  filename,
  fn,
  customUpload = 'false',
  fileExtension,
  customDoc,
  customInputUpload
) => {
  fileExtension = fileExtension && fileExtension.toLowerCase() === 'pdf' ? 'pdf' : 'jpg';
  const mimeType = fileExtension === 'pdf' ? 'application/pdf' : 'image/jpeg';
  return fetch(url)
    .then((res) => {
      return res.arrayBuffer();
    })
    .then((buf) => {
      const fieldValue = customUpload === 'true' ? filename : field
      let formData = new FormData();
      const country = getCountry();
      formData.append("region", country);
      customUpload === 'true' && formData.append("customDoc", customUpload);
      formData.append(
        fieldValue,
        new File([buf], `${filename}.${fileExtension}`, { type: mimeType })
      );
      if (customDoc === 'true') {
        formData.append("customDoc", customDoc);
      }
      if (getCountry() === 'HN') {
        formData.append("transaction_type", 'activation');
      }
      filePath && formData.append("fileName", `${filePath}/${filename}.${fileExtension}`);
      if (customInputUpload) return fn(formData, filename);
      return fn(formData);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getActivationJourney = (orderValue) => {
  switch (orderValue) {
    case 0:
      return "Morning";
    case 1:
      return "Afternoon";
    default:
      return "Morning/Afternoon";
  }
};

export const formattedavailabeISODate = (isoDates, dateKey = null) => {
  return isoDates.length
    ? isoDates.map((i) => moment(new Date(dateKey ? i[dateKey] : i.date)).utc().format("DD/MM/YYYY"))
    : [];
};

export const formattedISODates = (isoDates, isAM) => {
  const filteredDates = []
  if (isoDates.length) {
    if (isAM === 0) {
      isoDates.map((i) => {
        const { startDateTime } = i;
        const hr = moment(startDateTime).hour();
        if (Number(hr) < 12) {
          filteredDates.push(i)
        }
      })
    } else {
      isoDates.map((i) => {
        const { startDateTime } = i;
        const hr = moment(startDateTime).hour();
        if (Number(hr) > 12) {
          filteredDates.push(i)
        }
      })
    }
  }
  return filteredDates;
}

export const checkDUIValid = (id) => {
  const regex1 = /^[0-9]+(-)+[0-9]$/;
  const regex2 = /^[0-9]+$/;
  if (regex1.test(id) || regex2.test(id)) {
    return id;
  }
  return "";
};

export const replaceSpecialChars = (str) => {
  return str?.replace(/[&\/\\#,+\-()$~%.'":*?<>{}]/g, "");
};

export const isDdMmYyyyFormat = (string) => {
  return (
    /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3}$)/.test(string) ||
    /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/([12]\d{3}$)/.test(string)
  );
};

export const getISODateString = (date, format = '/') => {
  if (date) {
    const dateArr = date.split(format);
    const year = Number(dateArr[2]);
    const month = Number(dateArr[1] - 1);
    const day = Number(dateArr[0]);
    return moment(new Date(year, month, day));
  }
  return null;
};

export const getConcatanatedText = (originalText, newTexts) => {
  newTexts.forEach((text) => {
    originalText += " " + text;
  });

  return originalText;
};

export const mapPackageInfoToServiceInfo = (selectedPackages) => {
  const fixed = selectedPackages.filter((item) => item.type === "Single fixed")
    .length
  const tv = selectedPackages.filter((item) => item.type === "Single tv")
    .length
  const internet = selectedPackages.filter(
    (item) => item.type === "Single internet"
  ).length
  const bundle = selectedPackages.filter((item) => item.type === "Bundle")
    .length
  const dth = selectedPackages.filter((item) => item.type === "Single DTH")
    .length
  let filteredPackage = [];
  if (fixed) {
    filteredPackage = [
      ...filteredPackage,
      { quantity: fixed.toString(), type: "TELEFONIA FIJA" },
    ];
  }
  if (tv) {
    filteredPackage = [
      ...filteredPackage,
      { quantity: tv.toString(), type: "TV DIGITAL" },
      { quantity: tv.toString(), type: "TV ANALOGA" },
    ];
  }

  if (internet) {
    filteredPackage = [
      ...filteredPackage,
      { quantity: internet.toString(), type: "INTERNET" },
    ];
  }

  if (bundle) {

    const packageArr = filteredPackage
      .filter((i) => i.type !== "INTERNET" && i.type !== "TV DIGITAL" && i.type !== "TV ANALOGA")
    filteredPackage = [
      ...packageArr,
      { quantity: (Number(internet) + 1).toString(), type: "INTERNET" },
      { quantity: (Number(tv) + 1).toString(), type: "TV DIGITAL" },
      { quantity: (Number(tv) + 1).toString(), type: "TV ANALOGA" },
    ];
  }

  if (dth) {
    filteredPackage = [
      ...filteredPackage,
      { quantity: dth.toString(), type: "TV SATELITAL" },
    ];
  }

  return filteredPackage;
};

export const getServiceType = (type) => {
  switch (type) {
    case "Single fixed":
      return "TELEFONIA FIJA";
    case "Single tv":
      return "TV DIGITAL";
    case "Single internet":
      return "INTERNET";
    case "Single DTH":
      return "TV SATELITAL";
    default:
      return "";
  }
};

export const getPhoneNumberWithCountryCode = (phoneNumber) => {
  const country = getCountry();
  switch (country) {
    case "SV":
      return "503" + phoneNumber;
    case 'HN':
      return '504' + phoneNumber;
    case "CO":
      return "57" + phoneNumber;
    case "GT":
      return "502" + phoneNumber;
    case "BO":
      return "591" + phoneNumber;
    case "NI":
      return "505" + phoneNumber;
    case "CR":
      return "506" + phoneNumber;
    default:
      return phoneNumber;
  }
};

export const getGenderFromEsLocale = (gender) => {
  switch (gender) {
    case "Masculino":
      return "Male";
    case "Femenino":
      return "Female";
    default:
      return gender;
  }
};

export const isValidEmail = (email) => {
  const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  return emailRegex.test(email);
};

export const mapMonthContractToNumber = (value) => {
  switch (value) {
    case "0 meses":
      return "0";
    case "3 meses":
      return "3";
    case "6 meses":
      return "6";
    case "12 meses":
      return "12";
    case "18 meses":
      return "18";
    case "24 meses":
      return "24";
    default:
      return "0";
  }
};

export const getProductType = (selectedPackages) => {
  if (selectedPackages.length) {
    const resourceList = selectedPackages.filter((i) => i.resourceList);
    if (selectedPackages.find((i) => i.type === "Bundle")) {
      return "BUNDLE";
    } else if (selectedPackages.find((i) => i.type === "Single DTH")) {
      return "DTH";
    } else if (resourceList.find((i) => i.name === "One TV")) {
      return "ONE TV";
    } else {
      return "SINGLE";
    }
  }
};

export const getWorkingDate = (value) => {
  switch (value) {
    case "AM":
      return 0;
    case "PM":
      return 1;
    default:
      return 2;
  }
};

export const getPriceinCurrency = (price, currency = "$") => {
  if (price) {
    return `${currency}${price}`;
  }
  return null;
};

export const getAddons = (packagesList, type) => {
  switch (type) {
    case "Bundle":
      return packagesList.filter((item) => {
        const offer = item.offerType.type || item.offerType;
        if (typeof offer === "string") {
          return offer === "activation supplementary";
        }
        return !offer.includes("activation primary");
      });
    default:
      const filteredPackages = packagesList.filter(
        (item) => item.type === type
      );
      return filteredPackages.filter((item) => {
        const offer = item.offerType.type || item.offerType;
        if (typeof offer === "string") {
          return offer === "activation supplementary";
        }
        return !offer.includes("activation primary");
      });
  }
};

export const filterPackagesByType = (packagesList, type) => {
  switch (type) {
    case "bundle":
      return packagesList.filter((i) => i.type === "Bundle").filter(item => {
        const offer = item.offerType.type || item.offerType;
        if (typeof offer === "string") {
          return offer !== "activation supplementary";
        }
        return offer.includes("activation primary");
      }) || [];
    case "tv":
      return (
        packagesList
          .filter((i) => ["Single tv", "Single DTH"].includes(i.type))
          .filter((i) => {
            const offer = i.offerType.type || i.offerType;
            if (typeof offer === "string") {
              return offer !== "activation supplementary";
            }
            return offer.includes("activation primary");
          }) || []
      );

    case "internet":
      return (
        packagesList
          .filter((i) => ["Single internet", "Single fixed"].includes(i.type))
          .filter((i) => {
            const offer = i.offerType.type || i.offerType;
            if (typeof offer === "string") {
              return offer !== "activation supplementary";
            }
            return offer.includes("activation primary");
          }) || []
      );
    default:
      return [];
  }
};

export const isPackage = (item) => {
  const offer = item.offerType.type || item.offerType;
  return (
    [
      "Bundle",
      "Single tv",
      "Single internet",
      "Single DTH",
      "Single fixed",
    ].includes(item.type) &&
    offer !== "activation supplementary" &&
    offer.includes("activation primary")
  );
};

export const isPromotion = (item) => {
  const ot = item.offerType.type || item.offerType;
  if (typeof ot === "string") return false;
  return (
    (ot.includes("activation primary") ||
      ot.includes("activation supplementary")) &&
    ot.includes("activation tactical")
  );
};

export const isDiscount = ({ resourceList }) =>
  resourceList
    ? resourceList.reduce((pV, cV) => pV || cV.type === "discount", false)
    : false;

export const promoDiscountOrder = (packages) =>
  packages.sort((i1, i2) => {
    let val = +isPromotion(i2) * 2 - +isPromotion(i1) * 3;
    val += +isDiscount(i2) * 1 - +isDiscount(i1) * 2;
    return val + Math.sign(i2.priceList[0].value - i1.priceList[0].value);
  });

export const sortPackages = (packagesList) => {
  return packagesList.sort(
    (item1, item2) => item2.priceList[0].value - item1.priceList[0].value
  );
};

export const getDocumentType = (documentName) => {
  switch (documentName.toLowerCase()) {
    case "pasaporte":
      return "PP";
    case "carnet de residente":
      return "CR";
    case "documento único de identidad":
      return "DUI";
    default:
      return documentName;
  }
};

export const formattedGenderValue = (value) => {
  switch (value) {
    case "M":
      return "Masculino";
    case "F":
      return "Femenino";
    default:
      return value;
  }
};

export const isValidDate = (dob, name) => {
  const isValid =
    moment(dob, "DD-MM-YYYY", true).isValid() ||
    moment(dob, "DD/MM/YYYY", true).isValid();
  const selectedDate = moment(dob, "DD-MM-YYYY").toDate();
  const minimumDate = moment().subtract(18, "years").toDate();
  switch (name) {
    case "cliDob":
      return isValid && selectedDate <= minimumDate;
    case "cliDocExpiry":
      return isValid;
    default:
      return true;
  }
};

export const getDocIssuingCountry = (country) => {
  switch (country.toLowerCase()) {
    case "costa rica":
      return "CR";
    case "guatemala":
      return "GT";
    case "honduras":
      return "HN";
    case "el salvador":
      return "SV";
    default:
      return "SV";
  }
};

export const getWorkingInstallDate = (selectedDate, source) => {
  const formattedDate = selectedDate.format("YYYY-MM-DD");
  const filteredSource = source.filter((i) => moment(i.startDateTime).format("YYYY-MM-DD") === formattedDate);
  const time = moment(filteredSource[0].startDateTime).hour();
  if (Number(time < 12)) {
    return 0;
  } else {
    return 1;
  }
};

export const seperateDates = (date) => {
  const amDates = [];
  const pmDates = [];
  date.map((day) => {
    const hrs = moment(day).hour()
    if (Number(hrs < 12)) {
      amDates.push(day)
    } else {
      pmDates.push(day)
    }
  })
  console.log(amDates, pmDates)
  return { amDates, pmDates }
}

export const getInstallDate = (selectedDate, selectedTime, source) => {
  if (!selectedDate || !selectedTime) return {};
  if (!source) {
    return {
      actInstallDate: moment(selectedDate).format("DD/MM/YYYY"),
      actInstallJourney: selectedTime === "AM" ? "Morning" : "Afternoon",
    };
  }
  const formattedDate = selectedDate.format("YYYY-MM-DD");
  const filteredSource = source.filter((i) => i.date === formattedDate);
  let date;
  if (filteredSource.length === 1) {
    date = filteredSource[0];
  } else {
    date = filteredSource.find((s) => {
      let hour = s.hour.split(":")[0];
      if (selectedTime === "AM" && Number(hour < 12)) {
        return true;
      }
      if (selectedTime === "PM" && Number(hour > 12)) {
        return true;
      }
      return false;
    });
  }
  return {
    actInstallDate: moment(date.date).format("DD/MM/YYYY"),
    actInstallJourney: selectedTime === "AM" ? "Morning" : "Afternoon",
  };
};

export const getTimeFormatFromValue = (value) => {
  switch (value) {
    case 0:
      return "AM";
    case 1:
      return "PM";
    default:
      return "AM/PM";
  }
};

export const getDateESLocale = () => {
  const es = moment().locale("es");
  const currentDate = es.format("LL");
  const dateArr = currentDate.split(" ");
  const date = dateArr.slice(0, 3).reduce((curr, acc) => curr + " " + acc);
  const year = dateArr.slice(-1);
  return date + ", " + year;
};

export const updateObjValue = (obj, value, data) => {
  let shallowObj = { ...obj };
  Object.keys(shallowObj).forEach((key) => {
    shallowObj[key] = data && key !== "cliDUINumber" ? !!data[key] : value;
    if (key === "cliDUINumber" && data && data?.cliDUINumber?.length < 9) {
      shallowObj[key] = false;
    }
  });
  return shallowObj;
};

export const countriesList = () => [
  { code: "AF", name: "Afganistán" },
  { code: "AX", name: "Islas de Åland" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Argelia" },
  { code: "AS", name: "Samoa Americana" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguila" },
  { code: "AQ", name: "Antártida" },
  { code: "AG", name: "Antigua y Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaiyán" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Baréin" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Bielorrusia" },
  { code: "BE", name: "Bélgica" },
  { code: "BZ", name: "Belice" },
  { code: "BJ", name: "Benín" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bután" },
  { code: "BO", name: "Bolivia" },
  { code: "BQ", name: "Bonaire, San Eustaquio y Saba" },
  { code: "BA", name: "Bosnia y Herzegovina" },
  { code: "BW", name: "Botsuana" },
  { code: "BV", name: "Isla Bouvet" },
  { code: "BR", name: "Brasil" },
  { code: "IO", name: "Territorio Británico del Océano Índico" },
  { code: "VG", name: "Islas Vírgenes Británicas" },
  { code: "BN", name: "Brunei" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Camboya" },
  { code: "CM", name: "Camerún" },
  { code: "CA", name: "Canadá" },
  { code: "CV", name: "Cabo Verde" },
  { code: "KY", name: "Islas Caimán" },
  { code: "CF", name: "República de África Central" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Isla de Pascua" },
  { code: "CC", name: "Islas Cocos" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoras" },
  { code: "CK", name: "Islas Cook" },
  { code: "CR", name: "Costa Rica" },
  { code: "HR", name: "Croacia" },
  { code: "CU", name: "Cuba" },
  { code: "CW", name: "Curazao" },
  { code: "CY", name: "Chipre" },
  { code: "CZ", name: "República Checa" },
  { code: "CD", name: "República Democrática del Congo" },
  { code: "DK", name: "Dinamarca" },
  { code: "DJ", name: "Yibuti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "República Dominicana" },
  { code: "TL", name: "Timor Oriental" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egipto" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Guinea Ecuatorial" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Etiopía" },
  { code: "FK", name: "Islas Malvinas" },
  { code: "FO", name: "Islas Faroe" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finlandia" },
  { code: "FR", name: "Francia" },
  { code: "GF", name: "Guayana Francesa" },
  { code: "PF", name: "Polinesia Francesa" },
  { code: "TF", name: "Territorios del sur Franceses" },
  { code: "GA", name: "Gabón" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Alemania" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Grecia" },
  { code: "GL", name: "Groenlandia" },
  { code: "GD", name: "Granada" },
  { code: "GP", name: "Guadalupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haití" },
  { code: "HM", name: "Islas Heard y McDonald" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungría" },
  { code: "IS", name: "Islandia" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Irán" },
  { code: "IQ", name: "Irak" },
  { code: "IE", name: "Irlanda" },
  { code: "IM", name: "Isla de Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italia" },
  { code: "CI", name: "Costa de Marfil" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japón" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordania" },
  { code: "KZ", name: "Kazajistán" },
  { code: "KE", name: "Kenia" },
  { code: "KI", name: "Kiribati" },
  { code: "XK", name: "Kosovo" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kirguistán" },
  { code: "LA", name: "Laos" },
  { code: "LV", name: "Letonia" },
  { code: "LB", name: "Líbano" },
  { code: "LS", name: "Lesoto" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libia" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lituania" },
  { code: "LU", name: "Luxemburgo" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malaui" },
  { code: "MY", name: "Malasia" },
  { code: "MV", name: "Maldivas" },
  { code: "ML", name: "Malí" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Islas Marshall" },
  { code: "MQ", name: "Martinica" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauricio" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "México" },
  { code: "FM", name: "Micronesia" },
  { code: "MD", name: "Moldavia" },
  { code: "MC", name: "Mónaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Marruecos" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Países Bajos" },
  { code: "AN", name: "Antillas Holandesas" },
  { code: "NC", name: "Nueva Caledonia" },
  { code: "NZ", name: "Nueva Zelanda" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Níger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Isla Norfolk" },
  { code: "KP", name: "Corea del Norte" },
  { code: "MP", name: "Islas Marianas del Norte" },
  { code: "NO", name: "Noruega" },
  { code: "OM", name: "Omán" },
  { code: "PK", name: "Pakistán" },
  { code: "PW", name: "Palaos" },
  { code: "PS", name: "Territorios Palestinos" },
  { code: "PA", name: "Panamá" },
  { code: "PG", name: "Papúa Nueva Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Perú" },
  { code: "PH", name: "Filipinas" },
  { code: "PN", name: "Islas Pitcairn" },
  { code: "PL", name: "Polonia" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Catar" },
  { code: "CG", name: "República del Congo" },
  { code: "RE", name: "Reunión" },
  { code: "RO", name: "Rumanía" },
  { code: "RU", name: "Rusia" },
  { code: "RW", name: "Ruanda" },
  { code: "BL", name: "San Bartolomé" },
  { code: "SH", name: "Santa Elena" },
  { code: "KN", name: "San Cristóbal y Nieves" },
  { code: "LC", name: "Santa Lucía" },
  { code: "MF", name: "San Martín" },
  { code: "PM", name: "San Pedro y Miguelón" },
  { code: "VC", name: "San Vicente y las Granadinas" },
  { code: "WS", name: "Samoa Occidental" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Santo Tomé y Príncipe" },
  { code: "SA", name: "Arabia Saudita" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "CS", name: "Serbia y Montenegro" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leona" },
  { code: "SG", name: "Singapur" },
  { code: "SX", name: "San Martín" },
  { code: "SK", name: "Eslovaquia" },
  { code: "SI", name: "Eslovenia" },
  { code: "SB", name: "Islas Salomón" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "Sudáfrica" },
  { code: "GS", name: "Islas Georgia del Sur y Sandwich del Sur" },
  { code: "KR", name: "Corea del Sur" },
  { code: "SS", name: "Sudán del Sur" },
  { code: "ES", name: "España" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudán" },
  { code: "SR", name: "Surinam" },
  { code: "SJ", name: "Islas Svalbard y Jan Mayen" },
  { code: "SZ", name: "Suazilandia" },
  { code: "SE", name: "Suecia" },
  { code: "CH", name: "Suiza" },
  { code: "SY", name: "Siria" },
  { code: "TW", name: "Taiwán" },
  { code: "TJ", name: "Tayikistán" },
  { code: "TZ", name: "Tanzania" },
  { code: "TH", name: "Tailandia" },
  { code: "TG", name: "República Togolesa" },
  { code: "TK", name: "Islas Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad y Tobago" },
  { code: "TN", name: "Túnez" },
  { code: "TR", name: "Turquía" },
  { code: "TM", name: "Turkmenistán" },
  { code: "TC", name: "Islas Turcos y Caicos" },
  { code: "TV", name: "Tuvalu" },
  { code: "VI", name: "Islas Vírgenes de los EE.UU." },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ucrania" },
  { code: "AE", name: "Emiratos Árabes Unidos" },
  { code: "GB", name: "Reino Unido" },
  { code: "US", name: "Estados Unidos (USA)" },
  { code: "UM", name: "Islas menores alejadas de los Estados Unidos" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistán" },
  { code: "VU", name: "Vanuatu" },
  { code: "VA", name: "Vaticano" },
  { code: "VE", name: "Venezuela" },
  { code: "VN", name: "Vietnam" },
  { code: "WF", name: "Wallis y Futuna" },
  { code: "EH", name: "Sahara Occidental" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabue" },
];

export const countriesListByName = () => {
  return countriesList().map((country) => country.name);
};

export const getCountryCodeFromList = (selected) => {
  const selectedCountry = countriesList().find(
    (country) => country.name === selected
  );
  return selectedCountry.code;
};

export const getCountryNameFromList = (selected) => {
  if (selected === '[object Object') return 'El Salvador'
  if (selected === 'Salvadoreño') return 'El Salvador';
  const selectedCountry = countriesList().find(
    (country) => country.code === selected
  );
  return selectedCountry?.name || selected;
};

export const getCliBirthDetails = (momentDate) => {
  if (momentDate) {
    const formattedDate = moment(momentDate).format("DD/MM/YYYY").split("/");
    const [cliBirthDate, cliBirthMonth, cliBirthYear] = formattedDate;
    return { cliBirthDate, cliBirthMonth, cliBirthYear };
  }
  return { cliBirthDate: '', cliBirthMonth: '', cliBirthYear: '' }
};

export const getCliNameFormatted = (firstName, lastName) => {
  const formattedFirstName = firstName ? firstName.trim().split(" ") : '';
  const formattedLastName = lastName ? lastName.trim().split(" ") : '';
  const [cliFirstName, ...restFirstName] = formattedFirstName;
  const [cliLastName, ...restLastName] = formattedLastName;
  const cliSecondName = restFirstName.length
    ? restFirstName.reduce((curr, acc) => curr + " " + acc)
    : "";
  const cliSecondLastName = restLastName.length
    ? restLastName.reduce((curr, acc) => curr + " " + acc)
    : "";
  return { cliFirstName, cliLastName, cliSecondName, cliSecondLastName };
};

export const getTabName = (value, type) => {
  switch (value) {
    case 0:
      return type ? "PAQUETE" : "bundle";
    case 1:
      return type ? "TV" : "tv";
    case 2:
      return type ? "INTERNET" : "internet";
    default:
      return type ? "PAQUETE" : "bundle";
  }
};

export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

/* function to if SV ID card is valid */
export const isValidIdDUI = (duiNumber, docType) => {
  if (docType === "DUI") {
    /* return false if length is not valid */
    if (duiNumber.length < 9 || duiNumber === "000000000") {
      return false;
    }
    /* for valid length, calculate the sum of digits excluding the first after reversing */
    const number = duiNumber
      .split("")
      .map((n) => parseInt(n))
      .reverse();
    /* set first number as identification number  */
    const identificationNum = number.shift();
    const sum = number.reduce(
      (acc, curr, index) => acc + curr * (index + 2),
      0
    );
    const mod = sum % 10;
    /* document is valid if the result is either 0 or result === identification number */
    if (mod === 0 && identificationNum === 0) {
      return true;
    }
    return 10 - mod === identificationNum || 10 - mod === 0;
  }
  return true;
};

export const ISOdate = (value) => {
  return value.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};

export const visitType = (value) => {
  switch (value) {
    case "Instalation":
      return "install";
    default:
      return value;
  }
};

export const checkOjectValueBoolean = (value) => {
  return Object.keys(value).every((k) => !value[k]);
};

export const getSpanishMonths = () => [
  "Enero",
  "Febrero ",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const getDocumentId = (document) => {
  switch (document) {
    case "CC":
      return 1;
    case "CE":
      return 4;
    default:
      return 1;
  }
};

export const getAddonsOnlyCO = (selectedAddons) => {
  return selectedAddons.filter(
    (item) => item.offerType === "activation supplementary"
  );
};

export const numberFormatting = (number, format = "es-CO", foramtConfig = {}) => {
  const nfObject = new Intl.NumberFormat(format, foramtConfig);
  return nfObject.format(number);
};

export const FormatAmt = (amt) => {
  let totalAmount = amt;
  const number = (amt).toString().split(".");
  if (number.length === 1 || number[1]?.length < 3) {
    totalAmount = Number(totalAmount).toFixed(3)
  }
  return totalAmount;
}
export const stringifyProdCode = (actProduct) => {
  if (!actProduct?.length) return
  return actProduct.map((item) => {
    return { ...item, prodCode: item.prodCode.toString() };
  });
};

export const convertGenderValue = (value) => {
  switch (value) {
    case "Masculino":
      return "M";
    case "Femenino":
      return "F";
    default:
      return value;
  }
};

export const convertGenderKey = (key) => {
  switch (key) {
    case "M":
      return "Masculino";
    case "F":
      return "Femenino";
    default:
      return key;
  }
};
export const getDiscountInfo = (resourceList = []) => {
  if (resourceList) {
    const discountInfo = resourceList.find((r) => r.type === "discount");
    if (discountInfo) {
      return discountInfo.name;
    }
    return null;
  }
  return null;
};

export const getActJourneyAbbreviation = (value) => {
  switch (value) {
    case "Morning":
      return "AM";
    case "Afternoon":
      return "PM";
    default:
      return "AM/PM";
  }
};

export const getDocumentTypeForActivation = (documentName) => {
  switch (documentName.toLowerCase()) {
    case "pasaporte":
      return "PASAPORTE";
    case "carnet de residente":
      return "CRES";
    default:
      return documentName;
  }
};

export const removePathsFromS3Url = (attachments = []) => {
  const s3url = "s3.amazonaws.com/";
  return attachments.map((a) => ({
    ...a,
    location: a?.location?.includes(s3url) ? a.location.substr(a.location.indexOf(s3url) + s3url.length) : a.location,
  }));
};

export const addDocumentsToAttachments = ({
  attachments = [],
  documents = {},
  showUpdateStatus = false
}) => {
  for (const [key, value] of Object.entries(documents)) {
    let obj = { action: "A", label: "Recibo_Luz_Recibo_Agua", location: value?.imageUrl };
    let obj2 = { action: "A", label: "COMPROBANTE INGRESOS", location: value?.imageUrl };
    if (showUpdateStatus) {
      obj = { ...obj, updated: value?.updated }
      obj2 = { ...obj2, updated: value?.updated }
    }
    if (key === 'reciboEnergia') {
      attachments = attachments.concat({ ...obj });
    } else if (key === 'salary_proof') {
      attachments = attachments.concat({ ...obj2 });
    }
    else if (key !== 'reciboEnergia' && value) {
      attachments = attachments.concat({
        ...obj, label: value?.imageUrl?.split('/')?.pop()?.split('.')?.[0], location: value.imageUrl,
      });
    }
    else { }
  }
  return attachments;
};

export const getNodeNetworkType = (node) => {
  switch (node) {
    case 10:
      return 'FTTH';
    case 4:
      return "DTH";
    default:
      return "HFC";
  }
};

export const getNodeService = (node) => {
  switch (node) {
    case "S":
      return "TV & Internet";
    default:
      return "TV";
  }
};

export const removeAccents = (str) => {
  if (!str) return str;
  return str.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")
}


const userAgent = window.navigator.userAgent;
export const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);

export const getUploadCaptureText = (isUpload, defaultText) => {
  const uploadText = isUpload ? 'CARGAR FOTO DEL DUI' : ''
  return defaultText || uploadText
};

export const getRedirectValues = () => {
  const url = String(window.location.href).split("?")[1];
  const urlParams = new URLSearchParams(url);
  const code = urlParams.get("code") ? urlParams.get("code") : "";
  const serverState = urlParams.get("state") ? urlParams.get("state") : "";
  const error = urlParams.get("error") ? urlParams.get("error") : "";
  const localState = localStorage.getItem("TIGOPOASTATE");
  return { code, serverState, error, localState }
}

export const generateIntialFormData = ([...formData], store = {}) => {
  if (!formData.length) {
    return {}
  }
  let initialObjArr = [];
  let nestedArr = [];
  const formConstants = !isEmpty(store) ? { ...config.defaultFormValues[getCountry()], ...store } : config.defaultFormValues[getCountry()];
  const filteredFormData = formData.filter(f => !['button', 'button-group', 'label'].includes(f.type));
  filteredFormData.forEach((f, i) => {
    if (f.type === 'formRow') {
      f.components.map(c => {
        nestedArr = [...nestedArr, c.component[0]?.id]
        return null;
      })
      return
    }
    if (f.type === 'flexibleFormRow' && !formConstants[f.id]) {
      formConstants[f.id] = [
        f.components.reduce((defaultItem, item) => {
          if (item?.component[0]?.id === f.keyId && item?.component[0]?.options) {
            defaultItem[f.keyId] = item?.component[0]?.options[0]?.value || item?.component[0]?.options[0]
          }
          return defaultItem;
        }, { [f.keyId]: '', [f.valueId]: '' })
      ]
    }
    if (f.type === 'checkbox' && !formConstants[f.id]) {
      formConstants[f.id] = f.checked || "false";
    }
    if (f.type === 'inputList') {
      f.options.map(c => {
        nestedArr = [...nestedArr, c?.id]
        return null;
      })
      return
    }
    initialObjArr = [...initialObjArr, f?.id]
  })
  return [...nestedArr, ...initialObjArr].reduce((o, key) => ({ ...o, [key]: formConstants?.[key] ? formConstants?.[key] : '' }), {})
}


export const isDisabled = (sourceObj, optional = [], validateOnChange = true) => {
  if (!validateOnChange) return false;
  let obj = { ...sourceObj };
  if (optional.length) {
    optional.forEach(option => obj = { ...obj, [option]: '0' })
  }
  return Object.values(obj).some(el => {
    if (Array.isArray(el)) {
      return el.some(ob => Object.values(ob).some(item => item === ''))
    } else {
      return el === ''
    }
  });
}

export const checkMuncipDepartment = (value) => {
  return value && value !== ''
}


export const valueFormatter = (value, format) => {
  if (format && value) {
    return value?.replace(format.regex, format.identifier);
  }
  return value;
}

export const whiteSpaceFormatter = value => value.trim()?.replace(/\s(?=\s)/g, '');

export const removeWhiteSpace = value => value?.replace(/\s+/g, '');

export const getFilteredOptions = (options, type, store) => {
  switch (type) {
    case 'department':
      return options;
    case 'city':
      return options.filter(option => option['CODIGO_DEPTO'] === store.department['CODIGO_DEPTO']);
    default:
      return options
  }
}

export const getValueFromAutoComplete = (option, type) => {
  switch (type) {
    case 'department':
      return option['DEPARTAMENTO'];
    case 'city':
      return option['MUNICIPIO']
    case 'suburb':
      return option['description'];
    default:
      return ''
  }
}


export const autoResetDependencyList = dependency => dependency.reduce((o, key) => ({ ...o, [key]: '' }), {})

export const filterPackagesNI = (packages, type) => {
  switch (type.toUpperCase()) {
    case 'INTERNET + TV':
      return packages.filter(pkg => pkg.type.toUpperCase() === 'INTERNET + TV')
    case 'TV':
      return packages.filter(pkg => pkg.type.toUpperCase() === 'TV')
    case 'INTERNET':
      return packages.filter(pkg => pkg.type.toUpperCase() === 'INTERNET')
    default:
      return packages
  }
}

export const extractValueFromEntries = (obj, entry, key) => {
  const itemObj = get(obj, `${entry}.entries`, []).find(obj => obj.key === key) || {};
  return get(itemObj, 'value', '')
}

export const isValidDateHN = (date, month, year) => {
  const formattedYear = year || '1900';
  const formattedMonth = month || '01';
  const formattedDate = date || '01'
  return moment(`${formattedYear}-${formattedMonth}-${formattedDate}`, 'YYYY-MM-DD', true).isValid()
}

export const errorValidator = (validations, value) => {
  if (validations) {
    if (validations.minLength) {
      if (validations.minLength !== removeWhiteSpace(value).length) {
        return true
      }
    }
    if (validations.maxValue) {
      if (Number(value) > validations.maxValue) {
        return true
      }
    }
    if (validations.minValue) {
      if (Number(value) < validations.minValue) {
        return true
      }
    }
  }
  return false
}

export const getMonthInNumber = (value) => config.months.find(month => month.short.toLowerCase() === value.toLowerCase()).value

export const generateRandomAlphaNumeric = (length) => [...Array(length)].map(_ => (Math.floor((Math.random() * 36))).toString(36)).join('').toUpperCase();

export const convertToISOWithoutTimeZone = (date = Date.now()) => new Date(date).toISOString();

export const getProductCategory = name => {
  switch (name.toLowerCase()) {
    case 'single internet':
      return 'Internet';
    case 'single tv':
      return 'TV';
    case 'bundle':
      return 'bundle';
    default:
      return name;
  }
}

export const formatDocumentValueHN = (value) => {
  switch (value) {
    case 'CC - Cédula de identidad':
      return 'CC';
    case 'PA - Pasaporte':
      return 'PA';
    case 'CR - Carné de residente':
      return 'CR';
    default:
      return value;
  }
}

export const getDownPaymentStatus = formData => {
  const paymentOption = get(formData, 'data.paymentOption', '');
  switch (paymentOption) {
    case '0':
      return false;
    default:
      return true;
  }
};

export const getPaymentMethod = formData => {
  const paymentOption = get(formData, 'data.paymentOption', '');
  switch (paymentOption) {
    case '0':
      return 'Installation Payment';
    case '1':
      return 'cash';
    case '2':
      return 'Other';
    default:
      return ''
  }
};

export const getProductDetailsCO = pkg => {
  const entries = get(pkg, 'commercialEnrichmentsList[0].descriptions.entries', [])
  if (entries.length) {
    const { value: name } = entries.find(entry => entry.key === 'legal-name') || {};
    const { value: description } = entries.find(entry => entry.key === 'shortDescription') || {};
    const { value: addonLogo } = entries.find(entry => entry.key === 'logo') || {};
    const { value: discountDescription } = entries.find(entry => entry.key === 'discount_short_description') || {};
    return { description, addonLogo, discountDescription, name }
  }
  return { addonLogo: '', description: '', discountDescription: '', name: '' }
}

export const getClientCode = (agreementsList, orderId) => {
  if (agreementsList && agreementsList.length) {
    return agreementsList.reduce((prev, curr) => {
      const isExist = prev || curr.agreementItemsList.find(order => order.orderID === orderId);
      const currId = prev?.id || curr?.id
      if (isExist) {
        return { ...isExist, id: currId }
      }
      return undefined
    }, undefined);
  }
  return undefined;
}

export const getAddonFamilyName = (addon) => {
  if (addon && addon.length) {
    return addon.map(i => ({ name: i.name, description: i.description }))
  }
  return []
}


export const formatGeocodingParams = (value) => {
  const formattedValue = value.split('/');
  const slashRemovedValue = formattedValue[1] || formattedValue[0];
  return slashRemovedValue?.replace(/\s/g, '+')
}

export const setProductCategory = (tabValue) => {
  switch (tabValue) {
    case 0:
      return 'Paquete'
    case 1:
      return 'TV'
    case 2:
      return 'Internet'
    default:
      return ''
  }
}

export const getPrepaidBonusInfo = (bundledItems) => {
  if (bundledItems && bundledItems.length) {
    return bundledItems.reduce((prev, curr) => {
      const entriesInfo = curr?.commercialEnrichmentsList[0]?.descriptions?.entries || []
      const isExist = prev || (entriesInfo.find(entry => entry.key === 'requireMSISDN') && entriesInfo.find(entry => entry.value === 'TRUE'))
      if (isExist) {
        const productInfo = prev?.commercialEnrichmentsList[0]?.descriptions?.entries.find(entry => entry.key === 'short-description') || entriesInfo.find(entry => entry.key === 'short-description')
        return { ...isExist, productName: productInfo?.value }
      }
      return undefined
    }, undefined);

  }
  return undefined;
}

export const appendContractID = (filename, contractId) => filename?.substring(0, 22) + `${contractId}_` + filename?.substring(22, filename?.length);

export const updateFormError = (value, formFields, callback) => {
  const filtered = formFields.filter((field) => field.type !== "button");
  filtered.forEach((field) => {
    const { id } = field;
    if (!validateFormFields(id, value[id])) {
      callback({ id: [id], value: true });
    }
    callback({ id: [id], value: false });
  });
}

export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear()
}

export const nitIsValid = (nit) => {
  if (!nit) return false;
  if (nit?.toLowerCase() === 'cf') return true;
  const nitRegExp = new RegExp('^[0-9]+(-?[0-9kK])?$');
  if (!nitRegExp.test(nit)) return false;
  nit = nit.replace(/-/, '');
  const lastChar = nit.length - 1;
  const number = nit.substring(0, lastChar);
  const expectedCheker = nit.substring(lastChar, lastChar + 1).toLowerCase();

  let factor = number.length + 1;
  let total = 0;

  for (var i = 0; i < number.length; i++) {
    const character = number.substring(i, i + 1);
    const digit = parseInt(character, 10);

    total += (digit * factor);
    factor = factor - 1;
  }

  const modulus = (11 - (total % 11)) % 11;
  const computedChecker = (modulus === 10 ? "k" : modulus.toString());

  return expectedCheker === computedChecker;
}

export const maskChars = (str, length, maskChar) => {
  const strLength = str.length;
  const possibleLengthToMask = strLength - 20;
  const maskLength = possibleLengthToMask > length ? possibleLengthToMask : length
  return str.slice(0, -(maskLength)) + maskChar
}

export const phoneNumberWithoutCountryCode = (number, length = 8) => {
  if (number.length === length) {
    return number;
  }
  return number.slice(3)
}
export const makeList = (options) => {
  return options
    .map((o) => ({
      name: o.provinceName,
      displayName: o.provinceName,
      code: o.provinceCode,
    })
    ).sort(sortAlphabetically())
};

export const sortAlphabetically = (nameKey = 'name') => (a, b) => {
  const nameA = a[nameKey].toUpperCase();
  const nameB = b[nameKey].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export const formatSalesDate = (salesDate) => {
  if (salesDate) {
    const formattedDate = salesDate.split(" ");
    const date = formattedDate[0];
    return getISODateString(date)
  }
  return null
}

export const initializedocumentUploadForms = (storeObj) => {
  let fromStore = []
  if (!isEmpty(storeObj)) {
    for (const [key, value] of Object.entries(storeObj)) {
      if (!["reciboEnergia", 'salary_proof'].includes(key) && value) {
        fromStore.push({
          type: "upload",
          showInput: true,
          scannerConfig: {
            btnText: "Documento extra",
            field: "documentoExtra",
            getImageFunc: "uploadDocument",
            compressionConfig: {
              maxSizeMB: 0.5,
              useWebWorker: true,
            },
            count: value.count,
          },
          customUpload: true,
        });
      }
    }
  }
  return fromStore
}

export const getValueFromSalesReport = (salesReport, id) => {
  if (isEmpty(salesReport)) return
  const cliFirstLastName = salesReport?.cliFirstLastName || '';
  const cliSecondLastName = salesReport?.cliSecondLastName || '';
  switch (id) {
    case 'cliTelephone':
      return salesReport?.cliContactPhoneList?.[0]
    case 'cliTelephoneSecondary':
      return salesReport?.cliContactPhoneList?.[1]
    case 'refNameA':
      return salesReport?.cliReference?.[0]?.refName
    case 'refNameB':
      return salesReport?.cliReference?.[1]?.refName
    case 'refTelephoneA':
      return salesReport?.cliReference?.[0]?.refPhone
    case 'refTelephoneB':
      return salesReport?.cliReference?.[1]?.refPhone
    case 'street':
      return salesReport?.actAddress;
    case 'cliDocumentType':
      return getDocumentTypeFromSalesReport(salesReport?.cliDocument?.[0]?.cliDocumentType)
    case 'cliDUINumber':
      return salesReport?.cliDocument?.[0]?.cliDocumentNum
    case 'cliDob':
      return getISODateString(`${salesReport?.cliBirthDate}/${salesReport?.cliBirthMonth}/${salesReport?.cliBirthYear}`)
    case 'cliGender':
      return formattedGenderValue(salesReport?.cliGender)
    case 'issuingCountry':
      return getCountryNameFromList(salesReport?.cliNationality);
    case 'cliFirstName':
      return salesReport?.cliFirstName + " " + cliFirstLastName;
    case 'cliLastName':
      return salesReport?.cliLastName + " " + cliSecondLastName;
    default:
      return salesReport?.[id]
  }
}

export const removeSignatureKeysFromAttachment = (attachments, ignorekeysList) => {
  return attachments.filter(attachement => !ignorekeysList.includes(attachement.label))
}

export const getOnlyFileName = (filename) => {
  if (!filename) return '';
  const formattedValue = filename?.split('/');
  const length = formattedValue.length;
  return formattedValue?.[length - 1]
}

export const removeExtension = (fileName) => {
  const formattedValue = fileName?.split('.');
  return formattedValue?.[0]
}

export const getUniqueListBy = (arr, key) => {
  if (!arr.length) return [];
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const getDocumentTypeFromSalesReport = (documentName) => {
  switch (documentName.toUpperCase()) {
    case "PASAPORTE":
      return "Pasaporte";
    case "CRES":
      return "Carnet de residente";
    default:
      return documentName;
  }
};

export const getLabelforDropdown = (array, key) => {
  return array.map(x => x[key])
}


export const getIDfromValue = (array, retkey, valueKey, value) => {
  let retValue = null;
  array.map((x) => {
    if (x[valueKey] === value) retValue = x[retkey]
  })

  return retValue;

}

export const formatSalesDateNumber = (salesDate) => {
  if (salesDate) {
    const formattedDate = salesDate.split(" ");
    const date = formattedDate[0];
    return moment(new Date(date))
  }
  return null
}

export const validDocNum = (value) => {

  if (!value) return false;
  if (!/^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/.test(value)) return false;
  const cui = value.replace(/\s/, '');
  const depto = parseInt(cui.substring(9, 11), 10);
  const muni = parseInt(cui.substring(11, 13), 10);
  const numero = cui.substring(0, 8);
  const verificador = parseInt(cui.substring(8, 9), 10);
  let total = 0;
  let modulo = null;
  const munisPorDepto = [17, 8, 16, 16, 13, 14, 19, 8, 24, 21, 9,
    30, 32, 21, 8, 17, 14, 5, 11, 11, 7, 17];
  if (depto === 0 || muni === 0 || depto > munisPorDepto.length
    || muni > munisPorDepto[depto - 1]) {
    return false;
  }
  for (let i = 0; i < numero.length; i += 1) {
    total += numero[i] * (i + 2);
  }
  modulo = (total % 11);
  return modulo === verificador;

}


export const generateRandom = (length) => [...(window.crypto).getRandomValues(new Uint32Array(length))].map(x => (x).toString(36).slice(0, 1)).join('').toUpperCase();

export const priceFormat = (price) => {
  if ([0, '0', 0.0, '0.0', 0.0, '0.00'].includes(price)) {
    return '0.00';
  }
  return numberFormatting(price, 'en-US', { minimumFractionDigits: 2 });
};